import React from 'react';
import styled from 'styled-components';
import { Grid, GridCol } from '@naf/grid';
import { breakpoints } from '@naf/theme';
import { animated, useSpring } from '@react-spring/web';
import CarFilter from '../carfilter/CarFilter';
import useSelector from '../../../redux/typedHooks';
import CarSortDropdown from '../carSortDropdown/CarSortDropdown';
import CarSearch from '../carSearch/CarSearch';
import { UrlParamsType } from '../../../../../types/urlParamsType';

interface Props {
  additionalFilters?: UrlParamsType['filter'];
}

const CarSearchSort = ({ additionalFilters }: Props) => {
  const { allFilters } = useSelector((state) => state.carFilter);
  const { vehicleCounts } = useSelector((state) => state.cars);

  const { value } = useSpring({ value: vehicleCounts });

  return (
    <Wrapper>
      <Grid>
        <GridCol s="12" m="12" l="4" xl="4">
          <SearchWrapper>
            <CarSearch additionalFilters={additionalFilters} />
          </SearchWrapper>
        </GridCol>
        <CarFilterWrapper s="12" m="12" l="12" xl="12">
          {allFilters && <CarFilter additionalFilters={additionalFilters} />}
        </CarFilterWrapper>
        <StyledGridCol s="12" m="12" l="8" xl="8">
          <DropdownArea>
            <div>
              <animated.strong>{value.to((val) => Math.floor(val as number))}</animated.strong>
              <strong> treff</strong>
            </div>
            <CarSortDropdown />
          </DropdownArea>
        </StyledGridCol>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const SearchWrapper = styled.div`
  @media (min-width: ${breakpoints.l}) {
    width: calc(75% + 12px);
  }
`;

const StyledGridCol = styled(GridCol)`
  padding-right: 0;
`;

const CarFilterWrapper = styled(GridCol)`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
  }
`;

const DropdownArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-weight: bold;
  }

  button {
    margin-right: -16px;
  }
`;

export default CarSearchSort;
