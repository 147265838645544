import React, { useMemo } from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { differenceInBusinessDays } from 'date-fns';
import { ChargeTestType } from '../../../../../types/testType';
import { RelatedTestCard } from '../../related-cars/RelatedTestCard';
import { LayoutWithMainContent } from '../../layout/Layout';
import S from '../../../styles/bilguide/tests/StyledTests';
import SchemaArticle from '../../structured-data/SchemaArticle';
import BreadCrumb from '../../breadcrumb/BreadCrumb';
import BlockContent from '../../block-content/BlockContent';
import RelatedArticleList from '../../related-articles/RelatedArticleList';
import { cloudinaryImage } from '../../../utils/imageUrl';
import useSelector from '../../../redux/typedHooks';
import { createNorwegianDateString } from '../../../utils/createNorwegianDateString';
import FeedbackSurvey from '../../feedbacksurvey/FeedbackSurvey';
import { ChargeCurve } from '../chart/ChargeCurve';
import { useWindowLocation } from '../../../hooks/useWindowLocation';
import { useResponsiveImageSize } from '../../../hooks/useResponsiveImageSize';
import { InternalSearchCardType } from '../../layout/InternalSearchMetaData';

interface Props {
  test: ChargeTestType;
}

export const ChargeTest = ({ test }: Props) => {
  const {
    id,
    slug: testSlug,
    name,
    ingress,
    body,
    image,
    meta,
    summerStart_Percent,
    summerEnd_Percent,
    winterStart_Percent,
    winterEnd_Percent,
    relatedRangeTest,
    relatedContent,
    seoConfig,
    readings,
    vehicle,
    metaDisplay,
    userAccessibility,
  } = test;

  const { slug: carSlug, brandName, modelName } = vehicle || {};
  const displayName = name || `${brandName} ${modelName}`;

  const application = useSelector((state) => state.application);

  const responsiveImagesSize = useResponsiveImageSize(810);
  const mainImage =
    image?.publicId &&
    responsiveImagesSize &&
    responsiveImagesSize.width &&
    cloudinaryImage(image.publicId, application, responsiveImagesSize.width);

  const schemaImage = image && image.publicId && cloudinaryImage(image.publicId, application, 1200, 630);

  const createdAt = useMemo(() => {
    const date = new Date(meta.publishAt);
    return createNorwegianDateString(date);
  }, [meta.publishAt]);

  const path = useWindowLocation();

  const seoDescription = seoConfig?.introduction ? seoConfig.introduction : ingress;
  const seoTitle = seoConfig?.title ? seoConfig.title : name;

  return (
    <LayoutWithMainContent
      title={name}
      description={ingress}
      url={path}
      imgUrl={schemaImage}
      gtmArgs={{
        page_type: 'CarTestPage',
        pageCategory: `Bilguiden/${displayName}/${name}` || `Bilguiden/${name}` || '',
        contentId: id || '',
        author: meta?.editor?.name,
        headline: name,
        publishDate: meta?.publishAt,
        daysSincePublish: differenceInBusinessDays(new Date(), new Date(meta?.publishAt)),
        isPaywall: userAccessibility.viewType !== 'Free',
        viewType: userAccessibility.viewType,
      }}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Article,
        imagePublicId: image?.publicId,
      }}
    >
      <S.Wrapper>
        <SchemaArticle
          title={name}
          description={ingress}
          imgUrl={schemaImage}
          datePublished={meta.publishAt}
          dateModified={meta.updatedAt}
          url={`/bilguiden/bilmodell/${carSlug}/${testSlug}`}
          id={testSlug}
        />
        <Grid>
          <GridCol s="12" m="12" l="12" xl="12">
            <BreadCrumb />
            <GridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                {mainImage && (
                  <S.ImageWrapper>
                    <S.ImageFigure>
                      <S.Image src={mainImage} alt={image?.altText} />
                    </S.ImageFigure>
                  </S.ImageWrapper>
                )}
                {name && mainImage ? (
                  <S.Header>
                    <S.Title tag="h1" variant={TextVariant.Header1}>
                      {name}
                    </S.Title>
                  </S.Header>
                ) : (
                  <Text tag="h1" variant={TextVariant.Header1}>
                    {name}
                  </Text>
                )}
                <S.Ingress tag="p" variant={TextVariant.Ingress}>
                  {ingress}
                </S.Ingress>
                {userAccessibility.isAccessibleForUser && (
                  <ChargeCurve
                    summerData={
                      summerStart_Percent && summerEnd_Percent
                        ? {
                            startPercent: summerStart_Percent,
                            endPercent: summerEnd_Percent,
                            readings: Object.entries(readings).reduce((acc, [key, value]) => {
                              if (value?.summer_kW) {
                                acc[key.replace('_', '')] = value.summer_kW;
                              }
                              return acc;
                            }, {}),
                          }
                        : undefined
                    }
                    winterData={
                      winterStart_Percent && winterEnd_Percent
                        ? {
                            startPercent: winterStart_Percent,
                            endPercent: winterEnd_Percent,
                            readings: Object.entries(readings).reduce((acc, [key, value]) => {
                              if (value?.winter_kW) {
                                acc[key.replace('_', '')] = value.winter_kW;
                              }
                              return acc;
                            }, {}),
                          }
                        : undefined
                    }
                  />
                )}
                <S.Body>
                  <BlockContent value={body} />
                </S.Body>
                {userAccessibility.isAccessibleForUser && !metaDisplay?.hidePublicationDate && (
                  <S.Byline>{createdAt}</S.Byline>
                )}
                {userAccessibility.isAccessibleForUser && relatedRangeTest && relatedRangeTest.length > 0 && (
                  <>
                    <S.HRLine />
                    <Text tag="h2" variant={TextVariant.Header2}>
                      Les også
                    </Text>
                    <S.TestList>
                      {relatedRangeTest.map((rangeTest) => (
                        <S.TestListElement key={`relatedRangeTest-${rangeTest.name}`}>
                          <RelatedTestCard carTest={rangeTest} />
                        </S.TestListElement>
                      ))}
                    </S.TestList>
                  </>
                )}
                {userAccessibility.isAccessibleForUser && relatedContent && relatedContent.length > 0 && (
                  <>
                    {(!relatedRangeTest || relatedRangeTest?.length < 1) && (
                      <>
                        <S.HRLine />
                        <Text tag="h2" variant={TextVariant.Header2}>
                          Les også
                        </Text>
                      </>
                    )}
                    <RelatedArticleList relatedContent={relatedContent} />
                  </>
                )}
              </GridCol>
            </GridRow>
            {userAccessibility.isAccessibleForUser && !metaDisplay?.hideForm && (
              <GridRow>
                <GridCol s="12" m="12" l="8" xl="8">
                  <FeedbackSurvey contentUrl={path} />
                </GridCol>
              </GridRow>
            )}
          </GridCol>
        </Grid>
      </S.Wrapper>
    </LayoutWithMainContent>
  );
};
