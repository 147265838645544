/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { useDispatch } from 'react-redux';
import useSelector from '../../../redux/typedHooks';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import { actions as carsActions } from '../../../redux/modules/cars';
import CarSubList from './CarSubList';
import createAPIQuery from '../../../lib/createURLQueries';
import { getURLParams } from '../../../lib/getUrlParams';
import { UrlParamsType } from '../../../../../types/urlParamsType';
import { useSessionStorage } from '../../../hooks/useSessionStorage';

interface Props {
  filters?: UrlParamsType['filter'];
}

const CarList = ({ filters }: Props = { filters: {} }) => {
  const dispatch = useDispatch();

  const cars = useSelector((state) => state.cars);
  const { vehicleCounts, data: dataIds, mappedData } = cars;
  const data = dataIds.map((sublist) => sublist.map((id) => mappedData[id].data));
  const { scrollToLastCars, filterNumber, filterFetchedMap } = useSelector((state) => state.carFilter);
  const isUpdating = useSelector((state) => state.cars.isUpdating);
  const [currentScrollTop, setCurrentScrollTop] = useSessionStorage('scrollY', 0);

  // Update selection when filter selections change in url
  useEffect(() => {
    if (filterNumber !== 1 && filterFetchedMap[filterNumber] !== true) {
      const parsedParams = getURLParams();
      const queries = createAPIQuery(parsedParams, filters);
      dispatch(carsActions.getCars.request(queries));
      dispatch(carFilterActions.setDidRefreshSearch());
    }
  }, [filterNumber, filterFetchedMap, filters, dispatch]);

  // Update selection when no data is available
  useEffect(() => {
    if (
      typeof dataIds === 'undefined' ||
      dataIds.length === 0 ||
      (dataIds[0].length === 0 && filterFetchedMap[filterNumber] !== true)
    ) {
      const parsedParams = getURLParams();
      const queries = createAPIQuery(parsedParams, filters);
      dispatch(carsActions.getCars.request(queries));
      dispatch(carFilterActions.setDidRefreshSearch());
    }
  }, [dataIds, dispatch, filterFetchedMap, filterNumber, filters]);

  const carsCount = data.reduce((acc: number, element: any) => acc + element.length, 0);

  // * Scroll to next list after fetch
  useEffect(() => {
    if (currentScrollTop && data.length > 1 && scrollToLastCars) {
      window.scrollTo(0, currentScrollTop as number);
    }
  }, [data.length, currentScrollTop, scrollToLastCars]);

  const onShowMoreBtnClick = () => {
    // Need to check current skip
    const parsedParams = getURLParams();
    const queries = createAPIQuery(parsedParams, filters);
    dispatch(carsActions.getMoreCars.request(`${queries}&skip=${carsCount}&take=30`));
    dispatch(carFilterActions.setScrollToLastCars(true));

    setCurrentScrollTop(window.scrollY);
  };

  return (
    <>
      {isUpdating && <CarSubList cars={data[0]} key={`car-sub-list-placeholder-${1}`} isUpdating={isUpdating} />}
      {!isUpdating &&
        data.map((list, i) => {
          if (i < data.length - 1) {
            return <CarSubList cars={list} key={`car-sub-list-${i}`} isUpdating={isUpdating} />;
          }
          return <CarSubList cars={list} key={`car-sub-list-${i}`} isUpdating={isUpdating} />;
        })}
      {carsCount < vehicleCounts ? (
        <ShowMoreButtonWrapper>
          {carsCount < vehicleCounts && (
            <Button variant="outline" onClick={onShowMoreBtnClick}>
              Vis flere
            </Button>
          )}
          <p>
            Viser {carsCount} av {vehicleCounts}
          </p>
        </ShowMoreButtonWrapper>
      ) : (
        <p>
          Viser {carsCount} av {vehicleCounts}
        </p>
      )}
    </>
  );
};

const ShowMoreButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;

  p {
    margin-left: 20px;
  }
`;

export default CarList;
