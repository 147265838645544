import React from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { LayoutWithMainContent } from '../components/layout/Layout';
import useSelector from '../redux/typedHooks';
import S from '../styles/bilguide/StyledBilGuide';
import CarSearchSort from '../components/bilguide/carSearchSort/CarSearchSort';
import CarFilter from '../components/bilguide/carfilter/CarFilter';
import CarList from '../components/bilguide/carlist/CarList';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';

export const path = '';

const BilGuide = () => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Bilguiden');
  const carsArray = useSelector((state) => state.cars.data);
  const allFilters = useSelector((state) => state.carFilter.allFilters);
  const windowPath = useWindowLocation();

  return (
    <LayoutWithMainContent
      title="Biler i Norge - Bilguiden"
      description="To ganger i året tester vi rekkevidden og ladehastigheten til elbilene på det norske markedet. Her får du oversikt over alle bilene som er testet."
      url={windowPath}
      gtmArgs={{
        page_type: 'CarsPage',
        pageCategory: 'Bilguiden',
        contentId: '',
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      {carsArray.length === 0 ? (
        <h1
          style={{
            minHeight: '600px',
          }}
        >
          Henter bilene...
        </h1>
      ) : (
        <S.Wrapper>
          <Grid>
            <GridCol s="12" m="12" l="12" xl="12">
              <Text tag="h1" variant={TextVariant.Header1}>
                Bilguiden
              </Text>
              <S.IngressWrapper>
                <Text tag="p" variant={TextVariant.Ingress}>
                  To ganger i året tester vi rekkevidden og ladehastigheten til elbilene på det norske markedet. Her får
                  du oversikt over alle bilene som er testet.
                </Text>
              </S.IngressWrapper>
              <CarSearchSort />
            </GridCol>
            <S.LargeGrid>
              <GridCol l="4" xl="4">
                <S.CarFilterWrapper>{allFilters && <CarFilter additionalFilters={{}} />}</S.CarFilterWrapper>
                <S.DisclaimerWrapper>
                  <Text tag="p" variant={TextVariant.BodyText}>
                    Opplysningene er hentet og gjengitt med samtykke fra Opplysningsrådet for veitrafikken AS,{' '}
                    <a href="https://ofv.no/" target="_blank" rel="noreferrer">
                      ofv.no
                    </a>
                  </Text>
                </S.DisclaimerWrapper>
              </GridCol>
              <GridCol l="8" xl="8">
                <S.CarListWrapper>
                  <CarList filters={{}} />
                </S.CarListWrapper>
              </GridCol>
            </S.LargeGrid>
            <S.SmallGrid>
              <GridCol s="12" m="12">
                <S.CarListWrapper>
                  <CarList />
                </S.CarListWrapper>
              </GridCol>
            </S.SmallGrid>
          </Grid>
        </S.Wrapper>
      )}
    </LayoutWithMainContent>
  );
};

export default BilGuide;
