import styled from 'styled-components';
import React, { MouseEvent, useMemo, useState } from 'react';
import { isIE } from 'react-device-detect';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { Button } from '@naf/button';
import CarVariantFlyout from './CarVariantFlyout';
import CarVariantData from './CarVariantData';
import { CarVariant as CarVariantType } from '../../../../../types/carModelType';
import { CarVariantCard } from './CarVariantCard';

interface Props {
  displayName: string;
  data: CarVariantType[];
}

export const CarVariants = ({ displayName, data }: Props) => {
  const filteredData: CarVariantType[] = useMemo(
    () => data?.filter((e: CarVariantType) => e.vehicleMeta.transferred === null) || [],
    [data],
  );
  const DEFAULT_VARIANTS_TO_SHOW = 6;

  const [showFlyout, setShowFlyout] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<CarVariantType | null>(null);
  const [variantsToShowCount, setVariantsToShowCount] = useState(DEFAULT_VARIANTS_TO_SHOW);

  const variantMap = useMemo(
    () =>
      filteredData.reduce((acc: Record<CarVariantType['id'], CarVariantType>, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
    [filteredData],
  );

  const onCarVariantClick = (e: MouseEvent, id: string) => {
    e.preventDefault();
    setShowFlyout(!showFlyout);
    setSelectedVariant(variantMap[id]);
  };

  const onShowMoreBtnClick = () => {
    if (variantsToShowCount + DEFAULT_VARIANTS_TO_SHOW > filteredData.length) {
      setVariantsToShowCount(filteredData.length);
    } else {
      setVariantsToShowCount(variantsToShowCount + DEFAULT_VARIANTS_TO_SHOW);
    }
  };

  return (
    <div>
      <p>
        {displayName} finnes i {filteredData.length} varianter
      </p>
      <Grid>
        <StyledGridRow>
          {filteredData.slice(0, variantsToShowCount).map((variant) => (
            <GridCol key={variant.id} s="12" m="12" l="6" xl="6">
              <CarVariantCard
                displayName={displayName}
                variant={variant}
                onClick={(e: MouseEvent) => onCarVariantClick(e, variant.id)}
              />
              {isIE && <CarVariantData data={variant} />}
            </GridCol>
          ))}
        </StyledGridRow>
        <StyledGridRow>
          <GridCol s="12" m="12" l="12" xl="12">
            {variantsToShowCount < filteredData.length && (
              <ShowMoreButtonWrapper>
                <Button variant="outline" onClick={onShowMoreBtnClick}>
                  Vis flere
                </Button>
                <p>
                  Viser {variantsToShowCount} av {filteredData.length}
                </p>
              </ShowMoreButtonWrapper>
            )}
          </GridCol>
        </StyledGridRow>
      </Grid>
      {showFlyout && <CarVariantFlyout data={selectedVariant} onHandleClose={() => setShowFlyout(false)} />}
    </div>
  );
};

const StyledGridRow = styled(GridRow)`
  grid-gap: 32px !important;
`;

const ShowMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 20px;
  }
`;
