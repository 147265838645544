import styled from 'styled-components';
import { ChevronRight } from '@styled-icons/material/ChevronRight';
import { nafColor } from '@nafcore/theme';
import { Text, TextVariant } from '@naf/text';
import React from 'react';
import { numberWithSpaces } from '../../../utils';
import S from '../../../styles/bilguide/StyledBilGuide';
import { CarVariant as CarVariantType } from '../../../../../types/carModelType';

interface CarVariantCardProps {
  displayName: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  variant: CarVariantType;
}

export const CarVariantCard = ({ displayName, variant, onClick }: CarVariantCardProps) => (
  <CarVariant href="#" onClick={onClick}>
    <Text tag="h3" variant={TextVariant.Header3}>
      {variant.name ? variant.name : displayName}
    </Text>
    <ChevronRight size={24} />
    <InfoWrapper>
      <LabelList>
        {!!variant.electricVehicleDetails.rangeWLTP_km && (
          <S.LabelListElement>{variant.electricVehicleDetails.rangeWLTP_km} km rekkevidde</S.LabelListElement>
        )}
        {variant.energySource && <S.LabelListElement>{variant.energySource}</S.LabelListElement>}
        {variant.chassis && <S.LabelListElement>{variant.chassis}</S.LabelListElement>}
      </LabelList>
      {!!variant.priceNOK && <PriceLabel>fra {numberWithSpaces(variant.priceNOK)},-</PriceLabel>}
    </InfoWrapper>
  </CarVariant>
);

const CarVariant = styled.a`
  border: 1px solid #e8e7e8;
  border-radius: 4px;
  padding: 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 88px;
  text-decoration: none;

  &:hover {
    color: ${nafColor && nafColor.signature.black ? nafColor.signature.black : `#1A1A1A`};
  }

  svg {
    color: ${nafColor.primary.park};
  }

  h3 {
    position: relative;
    margin: 0;
    font-size: 1.125rem;

    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }

  &:hover {
    h3 {
      &::after {
        width: 100%;
      }
    }
  }
`;

const InfoWrapper = styled.div`
  margin-top: 4px;
  width: 100%;
`;

const PriceLabel = styled.p`
  font-size: 1rem;
  margin: 0px;
`;

const LabelList = styled(S.LabelList)`
  & {
    margin: 0;
    padding-right: 8px;
    margin-bottom: 4px;
    margin-top: 8px;
  }
`;
