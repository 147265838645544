const unitMap: Record<string, string> = {
  s: 'sekunder',
  km: 'kilometer',
  mm: 'millimeter',
  l: 'liter',
  kg: 'kilo',
  kwh: 'kilowatt',
  years: 'år',
  lper100km: 'liter per 100 km',
  kwhper10km: 'kW per 10 km',
  gperkm: 'gram per kilometer',
  mgperkm: 'milligram per kilometer',
  h: 'timer',
  nm: 'newtonmeter',
  percent: '%',
  v: 'volt',
};

export const removeDotInPrice = (price: string) => price.substring(0, price.indexOf('.'));

export const numberWithSpaces = (price: number | string | undefined) => {
  let formattedPrice = '';
  if (typeof price === 'number') {
    formattedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  if (typeof price === 'string' && price.includes('.')) {
    formattedPrice = removeDotInPrice(price);
    formattedPrice = formattedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else if (typeof price === 'string') {
    formattedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return formattedPrice;
};

export const mapUnitInData = (data: string) => {
  const formattedData = data.slice(data.lastIndexOf('_') + 1).toLowerCase();
  const unit = unitMap[formattedData];
  return unit || '';
};

const utils = { removeDotInPrice, numberWithSpaces, mapUnitInData };

export default utils;
