import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { actions as documentActions } from '../redux/modules/documents';
import useSelector from '../redux/typedHooks';
import { RangeTest } from '../components/bilguide/carTest/RangeTest';
import { ChargeTest } from '../components/bilguide/carTest/ChargeTest';
import { ChargeTestType, DocumentTypeLabel, RangeTestType } from '../../../types/testType';
import { LoaderPage } from './LoaderPage';
import { useAuth0Token } from '../hooks/useAuth0Token';
import { useIsDocumentLocationUrlValid } from '../hooks/useIsDocumentLocationUrlValid';
import { NotFound } from '../loadable-elements/NotFound';

interface Props {
  testSlug: string;
}

export const path = 'bilmodell/:carSlug/:testSlug';

const Test = ({ testSlug }: Props) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Test');
  const test = useSelector((state) => state.documents.mappedData[testSlug]?.data);
  const isUpdatingDocument = useSelector((state) => state.documents.mappedData[testSlug]?.meta.isUpdating);
  const { simpleToken } = useAuth0Token();
  const dispatch = useDispatch();
  const isValid = useIsDocumentLocationUrlValid(testSlug);

  const type = test && (test as ChargeTestType | RangeTestType).meta?.contentType;

  useEffect(() => {
    if (testSlug) {
      dispatch(documentActions.getDocument.request(testSlug, { token: simpleToken }));
    }
  }, [testSlug, simpleToken, dispatch]);

  const currentTest = test as ChargeTestType | RangeTestType;

  if (type && currentTest && isValid) {
    switch (type) {
      case DocumentTypeLabel.nafnoArticleRange:
        return <RangeTest test={currentTest as RangeTestType} />;
      case DocumentTypeLabel.nafnoArticleCharge:
        return <ChargeTest test={currentTest as ChargeTestType} />;
      default:
        return <LoaderPage />;
    }
  }

  if (!test && isUpdatingDocument === true) {
    // If there is no data, but the document is loading -> render loader
    return <LoaderPage />;
  }
  if ((!isValid || !test) && isUpdatingDocument === false) {
    // Regardless of data - If the document is not loading and the current location is invalid, render 404 page
    return <NotFound />;
  }
  return null;
};

export default Test;
