import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from '@naf/dropdown';
import { updateUrlQueryValues } from '../../../utils/updateUrlQueryValues';
import { sortByType } from '../../../../../types/sortByType';
import { actions as carFilterActions } from '../../../redux/modules/carFilter';
import { getURLParams } from '../../../lib/getUrlParams';

interface DropdownValue {
  label: string;
  value: sortByType;
  optionNumber: number;
}

const dropdownContent: Record<sortByType, DropdownValue> = {
  [sortByType.APPROVAL_DATE_DESCENDING]: {
    label: 'Nyest til eldst',
    value: sortByType.APPROVAL_DATE_DESCENDING,
    optionNumber: 1,
  },
  [sortByType.APPROVAL_DATE_ASCENDING]: {
    label: 'Eldst til nyest',
    value: sortByType.APPROVAL_DATE_ASCENDING,
    optionNumber: 2,
  },
  [sortByType.MODEL_NAME]: {
    label: 'Modellnavn: A - Z',
    value: sortByType.MODEL_NAME,
    optionNumber: 3,
  },
  [sortByType.LOW_TO_HIGH]: {
    label: 'Laveste pris',
    value: sortByType.LOW_TO_HIGH,
    optionNumber: 4,
  },
  [sortByType.HIGH_TO_LOW]: {
    label: 'Høyeste pris',
    value: sortByType.HIGH_TO_LOW,
    optionNumber: 5,
  },
  [sortByType.WLTP_ASCENDING]: {
    label: 'Kortest rekkevidde',
    value: sortByType.WLTP_ASCENDING,
    optionNumber: 6,
  },
  [sortByType.WLTP_DESCENDING]: {
    label: 'Lengst rekkevidde',
    value: sortByType.WLTP_DESCENDING,
    optionNumber: 7,
  },
};

const options = Object.values(dropdownContent).sort((e1, e2) => e1.optionNumber - e2.optionNumber);

const CarSortDropdown = () => {
  const [selectedValue, setSelectedValue] = useState<DropdownValue>(
    dropdownContent[sortByType.APPROVAL_DATE_DESCENDING],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const parsedParams = getURLParams();
      if (typeof parsedParams.sortBy !== 'undefined') {
        setSelectedValue(dropdownContent[parsedParams.sortBy as sortByType]);
      }
    }
  }, []);

  const handleSelect = (value: DropdownValue) => {
    updateUrlQueryValues.updateSortByValue(value.value);
    dispatch(carFilterActions.refreshSearch());
    setSelectedValue(value);
  };

  return (
    <Dropdown
      options={options}
      justify="right"
      selected={selectedValue}
      handleSelect={(value: DropdownValue) => handleSelect(value)}
    />
  );
};

export default CarSortDropdown;
