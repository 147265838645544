import styled from 'styled-components';
import React, { useMemo } from 'react';
import { breakpoints } from '@naf/theme';
import { BusinessCenter } from '@styled-icons/material-outlined/BusinessCenter';
import { LocalGasStation } from '@styled-icons/material-outlined/LocalGasStation';
import { OfflineBolt } from '@styled-icons/material-outlined/OfflineBolt';
import { CreditCard } from '@styled-icons/material/CreditCard';
import { Refresh } from '@styled-icons/material/Refresh';
import { nafColor } from '@nafcore/theme';
import RvHookupIcon from '../../icons/RvHookup';
import useSelector from '../../../redux/typedHooks';
import { terms as T, termsMapper as TMapper } from '../../../utils/bilguide';
import { numberWithSpaces } from '../../../utils';
import { CarVariant as CarVariantType } from '../../../../../types/carModelType';

interface Props {
  carSlug: string;
}

const CarProperties = ({ carSlug }: Props) => {
  const carModelState = useSelector((state) => state.cars.mappedData[carSlug].data);
  const {
    fuelCategory,
    driveTrain,
    vehicleVariants,
    modelMinimumPrice_NOK,
    cargoSpaceVolume_l,
    trailerMaximumWeight_kg,
    electricVehicleDetails,
    maxWltp_km,
    minWltp_km,
  } = carModelState || {};

  const filteredVehicleVariants: CarVariantType[] =
    useMemo(
      () => vehicleVariants && vehicleVariants.filter((e: CarVariantType) => e.vehicleMeta.transferred === null),
      [vehicleVariants],
    ) || [];
  const variantCounts = filteredVehicleVariants?.length;

  const renderCarRange = () => {
    let rangeMin = minWltp_km;
    let rangeMax = maxWltp_km;
    let rangeLabel = TMapper(T.RANGE_WLTP);
    // Get nedc if wltp is zero
    if (!minWltp_km && !maxWltp_km) {
      const nedc = electricVehicleDetails?.map(({ rangeNEDC_km }) => rangeNEDC_km);
      if (nedc && nedc.length > 0) {
        rangeMin = Math.min(...nedc);
        rangeMax = Math.max(...nedc);
        rangeLabel = TMapper(T.RANGE_NEDC);
      }
    }
    return (
      (!!rangeMin || !!rangeMax) && (
        <Property>
          <OfflineBolt size={24} />
          <div>
            <strong>
              <span>{!!rangeMin && `${rangeMin}`}</span>
              {!!rangeMax && rangeMax !== rangeMin && <span>{rangeMin ? ` - ${rangeMax}` : `${rangeMax}`}</span>}
              <span> km</span>
            </strong>
            <p>{rangeLabel}</p>
          </div>
        </Property>
      )
    );
  };
  return (
    <Wrapper>
      {fuelCategory && (
        <Property>
          <LocalGasStation size={24} />
          <div>
            <strong>
              {fuelCategory.reduce((acc: string, element: string) => `${acc}${element}/`, '').slice(0, -1)}
            </strong>
            <p>{TMapper(T.FUEL_CATEGORIES)}</p>
          </div>
        </Property>
      )}

      {renderCarRange()}
      <Property>
        <CreditCard size={24} />
        <div>
          <strong>
            {variantCounts !== 0 && modelMinimumPrice_NOK
              ? `Fra ${numberWithSpaces(modelMinimumPrice_NOK)},-`
              : 'Mangler prisdata'}
          </strong>
          {(variantCounts === 0 && <p>Denne modellen selges ikke som nybil lenger</p>) || null}
          {(variantCounts === 1 && <p>1 variant</p>) || null}
          {(variantCounts > 1 && <p>{variantCounts} varianter</p>) || null}
        </div>
      </Property>
      {driveTrain && (
        <Property>
          <Refresh size={24} />
          <div>
            <strong>{driveTrain[0]}</strong>
            <p>{TMapper(T.DRIVE_TRAIN)}</p>
          </div>
        </Property>
      )}

      {!!trailerMaximumWeight_kg && (
        <Property>
          <RvHookupIcon />
          <div>
            <strong>{trailerMaximumWeight_kg} kg</strong>
            <p>{TMapper(T.MAX_TOW_WEIGHT)}</p>
          </div>
        </Property>
      )}

      {cargoSpaceVolume_l && cargoSpaceVolume_l.length > 0 && !!cargoSpaceVolume_l[cargoSpaceVolume_l.length - 1] && (
        <Property>
          <BusinessCenter size={24} />
          <div>
            <strong>
              <span>{cargoSpaceVolume_l[0] && cargoSpaceVolume_l[0]}</span>
              <span>
                {cargoSpaceVolume_l.length > 1 &&
                  cargoSpaceVolume_l[cargoSpaceVolume_l.length - 1] !== cargoSpaceVolume_l[0] &&
                  ` - ${cargoSpaceVolume_l[cargoSpaceVolume_l.length - 1]}`}
              </span>
              <span> liter</span>
            </strong>
            <p>{TMapper(T.CARGO_SPACE_VOLUME)}</p>
          </div>
        </Property>
      )}
    </Wrapper>
  );
};
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${breakpoints.l}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  strong {
    line-height: 1;
    font-weight: 500;
  }
`;

export const Property = styled.div`
  display: flex;
  height: initial;
  margin-bottom: 24px;

  @media (max-width: ${breakpoints.l}) {
    flex-basis: 50%;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    margin-top: 4px;
  }

  svg {
    color: ${nafColor.primary.park};
    margin-right: 12px;
    display: inline-block;
    width: 24px;
    height: 24px;

    @media (max-width: ${breakpoints.m}) {
      margin-right: 8px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
  }
`;
export default CarProperties;
