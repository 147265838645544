import React from 'react';

const RvHookupIcon = () => (
  <svg viewBox="-3 0 24 12" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3V0H7H0V3C0 4.1 0.9 5 2 5H4C4 6.66 5.34 8 7 8C8.66 8 10 6.66 10 5H18V3H16ZM6 5C6 5.55 6.45 6 7 6C7.55 6 8 5.55 8 5C8 4.45 7.55 4 7 4C6.45 4 6 4.45 6 5Z"
    />
  </svg>
);

export default RvHookupIcon;
